// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('bootstrap')
require('jquery')
require('gritter/js/jquery.gritter.js')

// require('stylesheets/app.scss')

require('moment/locale/ja')
require('tempusdominus-bootstrap-4')
import '@fortawesome/fontawesome-free/js/all'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import bsCustomFileInput from 'bs-custom-file-input'

$(document).on('ready turbolinks:load', function () {
  bsCustomFileInput.init()

  $('#selectAll').click(function () {
    $('input[type=checkbox]').prop('checked', $(this).prop('checked')).closest('.divTableRow').addClass('highlight')
  })

  $('input[type=checkbox]').click(function () {
    if (!$(this).prop('checked')) {
      $('#selectAll').prop('checked', false)
      $('.divTableRow').removeClass('highlight')
    }
  })

  $('input[type=file]').change(function () {
    if ($(this).val()) {
      $('button#upload_button').prop('disabled', false)
    }
  })

 

  $('input[type=checkbox]').change(function () {
    if (document.querySelectorAll('input[type=checkbox]:checked').length > 0) {
      $('button#update_button').prop('disabled', false).removeClass('btn-secondary').addClass('btn-success')
      $('button#submit_errors').prop('disabled', false).removeClass('btn-secondary').addClass('btn-warning')
      $('button#update_address_button').prop('disabled', false).removeClass('btn-secondary').addClass('btn-primary')
      $('input[type=checkbox]:checked').closest('.divTableRow').addClass('highlight')
    } else {
      $('button#update_address_button').prop('disabled', true).removeClass('btn-primary').addClass('btn-secondary')
      $('button#update_button').prop('disabled', true).removeClass('btn-success').addClass('btn-secondary')
      $('button#submit_errors').prop('disabled', true).removeClass('btn-warning').addClass('btn-secondary')
    }
  })

  $('input[type=checkbox]').change(function () {
    if (document.querySelectorAll('input[type=checkbox]:checked').length > 0) {
      $('button#update_address_button').prop('disabled', false).removeClass('btn-secondary').addClass('btn-primary')
      $('input[type=checkbox]:checked').closest('.divTableRow').addClass('highlight')
    } else {
      $('button#update_address_button').prop('disabled', true).removeClass('btn-primary').addClass('btn-secondary')
    }
  })

  // Renders the number of selected orders
  const selectedElm = document.getElementById('selected')
  const showChecked = () => {
    if (document.querySelector('input[name=selectAll]:checked')) {
      selectedElm.innerHTML = document.querySelectorAll('input[type=checkbox]:checked').length - 1
    } else {
      selectedElm.innerHTML = document.querySelectorAll('input[type=checkbox]:checked').length
    }
  }

  document.querySelectorAll('input[type=checkbox]').forEach((i) => {
    i.onclick = () => showChecked()
  })
})
